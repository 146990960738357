<template>
  <div id="app">
    <router-view></router-view>
    <Footer v-show="path !== '/error_h5'"></Footer>
  </div>
</template>


<script>
import Footer from "@/components/Footer.vue";

export default {
  components: {Footer},
  data() {
    return {
      path: ''
    };
  },
  mounted() {
    this.path = this.$route.path;
  },
  created() {
    if (this._isMobile()) {
      console.log("手机端");
    } else {
      console.log("pc端");
    }
  },
  methods: {
    // 判断手机端还是PC端
    _isMobile() {
      return navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
  },
};
</script>

<style lang="less" scoped>
</style>
