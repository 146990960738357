<template>
  <div class="contain">
    <NavBar :isBlack="true"></NavBar>
    <div class="banner">
      <div class="banner-content">
        <div class="b-title">
          <span class="text-one">服务</span>
          <span>与支持</span>
        </div>
        <div class="b-des">基于业务交易场景提供资金分账能力，</div>
        <div class="b-des last">助力企业实现安全合规，提高生产效率。</div>
      </div>
      <About></About>
    </div>

    <div>
      <img alt="" src="../assets/images/s-market.png">
    </div>

    <div>
      <img alt="" src="../assets/images/s-process.png">
    </div>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import About from "@/components/About.vue";
export default {
  components: {About, NavBar},
  data() {
    return {};
  },
  mounted() {
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.contain{
  background: #F4F7FC;
}
.banner {
  position: relative;
  background-image: url("../assets/images/compliance-bg1.png");
  background-position: center;
  background-size: cover;
}

.banner-content {
  width: 75%;
  margin: 0 auto;
  padding: 100px 0;
  color: #242424;

  .b-title {
    font-size: 48px;
    margin-bottom: 34px;
    .text-one{
      color: #436CE9;
    }
  }

  .b-des {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
  }
  .last{
    margin-bottom: 150px;
  }
}

.title-box {
  font-size: 20px;
  color: #3370FF;
  text-align: center;
  margin-bottom: 74px;

  .title {
    font-size: 36px;
    color: #1D2129;
    font-weight: bold;
    margin-bottom: 18px;
  }
}

</style>