import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import Compliance from '../views/Compliance.vue'
import Payment from '../views/Payment.vue'
import Service from '../views/Service.vue'
import Docs from '../views/Docs.vue'
import Error_h5 from '../views/error/error_h5.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Home,
}, {
    path: '/compliance',
    component: Compliance,
}, {
    path: '/payment',
    component: Payment,
}, {
    path: '/service',
    component: Service,
},
{
    path: "/docs",
    name: "Docs",
    component: () => import("@/views/Docs.vue"),
},{
  path: '/error_h5',
  component: Error_h5
}]

const router = new VueRouter({
    routes
})

export default router