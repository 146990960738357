var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"footer-top"},[_c('ul',{staticClass:"row"},[_vm._m(1),_vm._m(2),_c('li',[_c('dl',[_c('dt',[_vm._v("开发者")]),_vm._m(3),_vm._m(4),_c('dd',[_c('router-link',{attrs:{"to":"/docs"}},[_vm._v("API 文档")])],1)])]),_vm._m(5),_vm._m(6)])]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"text"},[_vm._v("让交易更有价值")]),_c('div',{staticClass:"button"},[_vm._v("获取专属解决方案")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('dl',[_c('dt',[_vm._v("产品")]),_c('dd',[_c('a',[_vm._v("合规分账")])]),_c('dd',[_c('a',[_vm._v("聚合支付")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('dl',[_c('dt',[_vm._v("解决方案")]),_c('dd',[_c('a',[_vm._v("电商行业")])]),_c('dd',[_c('a',[_vm._v("B2B 供应链")])]),_c('dd',[_c('a',[_vm._v("产业互联网")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dd',[_c('a',[_vm._v("开发者中心")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dd',[_c('a',[_vm._v("帮助中心")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('dl',[_c('dt',[_vm._v("探索聚亿")]),_c('dd',[_c('a',[_vm._v("关于我们")])]),_c('dd',[_c('a',[_vm._v("条款")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('dl',[_c('dt',{attrs:{"id":"link"}},[_vm._v("联系我们")]),_c('dd',[_c('a',[_vm._v("客服1号QQ:1978731717")])]),_c('dd',[_c('a',[_vm._v("客服2号QQ:3329556783")])]),_c('dd',[_c('a',[_vm._v("客服3号QQ:3976617025")])]),_c('dd',[_c('a',[_vm._v("客服电话 :18559629661")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"icpBox"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_c('span',[_vm._v("闽ICP备2023021223号-1")])]),_c('span',{staticClass:"line"},[_vm._v("|")]),_c('span',{staticClass:"hj-copyright"},[_vm._v("Copyright © 2008 - 2024 聚在亿起（厦门）科技有限公司 版权所有")]),_c('span',[_vm._v("本站已支持IPV6")])])])
}]

export { render, staticRenderFns }