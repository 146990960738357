<template>
  <div :class="isBlack?'navbar-black':''" class="navbar-box">
    <div class="navbar">
      <div class="navbar__logo">
        <img v-if="isBlack" alt="logo" src="../assets/images/logo-black.png"/>
        <img v-else alt="logo" src="../assets/images/logo.png"/>
      </div>
      <div class="navbar__menu_p" @click="showMenu">
        <div :class="isBlack?'menu-line-black':''" class="menu-line"></div>
      </div>
      <div :class="isBlack?'menu-black':''" class="navbar__menu">
        <router-link to="/" :key="$route.fullPath">首页</router-link>
        <div class="dropdown">
          <a class="dropdown-name">产品</a>
          <div class="dropdown-content">
            <router-link to="/compliance">合规分账</router-link>
            <router-link to="/payment">聚合支付</router-link>
          </div>
        </div>
        <router-link to="/service">服务与支持</router-link>
      </div>
    </div>

    <div class="menu-imp" v-show="isShow">
      <div class="top">
        <img  alt="logo" src="../assets/images/logo.png"/>
        <div @click="closeMenu">×</div>
      </div>
      <ul class="menu-item">
        <li>
          <router-link to="/">首页</router-link>
        </li>
        <li>
          <dl>
            <dt>产品</dt>
            <dd>
              <router-link to="/compliance">- 合规分账</router-link>
            </dd>
            <dd>
              <router-link to="/payment">- 聚合支付</router-link>
            </dd>
          </dl>
        </li>
        <li>
          <router-link to="/service">服务与支持</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBlack: Boolean,
  },
  data() {
    return {
      isShow:false
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
    showMenu() {
      this.isShow = true
    },
    closeMenu(){
      this.isShow = false
    }
  },
};
</script>

<style lang="less" scoped>
.navbar-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100vw;
  background: rgba(56, 102, 224, 0.9);
  height: 70px;
}

.navbar-black {
  background: #fff;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 75%;
  height: 100%;
  margin: 0 auto;
}

.navbar__logo {
  width: 130px;
  height: auto;
  padding-top: 2px;

  img {
    height: auto !important;
  }
}

.navbar__menu {
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin: 0 25px;
    text-decoration: none;
  }
}

.menu-black {
  a {
    display: inline-block;
    padding: 10px 0;
    color: #4A4A4A !important;
  }

  .dropdown .dropdown-name::after {
    border: solid #4A4A4A;
    border-width: 0 2px 2px 0;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 4px;
  padding: 5px;

  .dropdown-name::after {
    position: absolute;
    content: "";
    top: 44%;
    right: 4px;
    transform: translateY(-50%) rotate(45deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  a {
    color: #4A4A4A !important;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  display: none;
  position: absolute;
  z-index: 2;
  width: 140px;
  padding: 10px;
  background: #fefefe;
  border-radius: 3px;
  border: 1px solid #f3f3f3 !important;
  box-shadow: 0px 5px 10px 0px rgba(12, 14, 44, 0.12);
}

.dropdown-content a:hover {
  color: #0580FF !important;
}

.navbar__menu_p {
  display: none;
  padding: 10px;

  .menu-line {
    box-sizing: content-box;
    padding: 5px 0;
    width: 25px;
    height: 2.4px;
    background-color: #fff;
    background-clip: content-box; //背景颜色从内容区域开始绘制
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }

  .menu-line-black {
    background-color: #000;
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
  }
}

.menu-imp{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  .top{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    img{
      width: 180px;
      height: auto;
    }
    div{
      font-size: 40px;
      font-weight: bolder;
    }
  }
  .menu-item{
    padding: 20px 10px;
    font-size: 26px;
    opacity: 0.8;
    li{
      padding: 10px 40px;
    }
    a{
      color: #fff;
      display: block;
      width: 100%;
      height: 100%;
    }
    dt{
      margin-bottom: 15px;
    }
    dd{
      padding: 15px 12px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    max-width: 95%;
    padding: 0 10px;
  }

  .navbar__menu {
    display: none;
  }

  .navbar__menu_p {
    display: block;
  }
}

</style>