<template>
  <div class="error-page"
       style="height: 100%;">
    <span>{{ errorTitle }}</span>

    <div v-if="2 == type">
      <img style="height: 200px;width: 200px;"
           src="../../assets/images/gzh_logo.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data () {
    return {
      errorTitle: "",
      errorMessage: "",
      type: '',
      timer: null, // 定时器
      totalTime: 5, // 10秒
    }
  },
  created () {
    this.type = this.$route.query.type
    if (1 == this.type) {
      this.errorTitle = "绑定失败，请联系平台！"
    } else if (2 == this.type) {
      this.errorTitle = "请先关注"
    } else if (3 == this.type) {
      this.errorTitle = "请使用微信扫码"
    } else if (4 == this.type) {
      this.errorTitle = "已授权绑定"
    } else if (6 == this.type) {
      this.errorTitle = "请重新扫码"
    } else {
      this.errorTitle = "绑定成功！"
    }
    
    this.timer = setInterval(() => {
          this.totalTime--;
          if (this.totalTime <= 0) {
            console.log(typeof WeixinJSBridge)
            if (typeof WeixinJSBridge == "undefined") {
              window.close();
            } else {
              WeixinJSBridge.call('closeWindow');
            }
          }
        }, 1000)
  }
}
</script>

<style>
.error-page {
  text-align: center;
  margin-top: 50px;
}
.error-page span {
  font-size: 36px;
  margin-bottom: 20px;
  color: #f80404;
}
.error-page p {
  font-size: 18px;
  color: #666;
}
</style>
