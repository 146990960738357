<template>
  <div class="contain">
    <NavBar :isBlack="true"></NavBar>
    <div class="banner">
      <div class="banner-content">
        <div class="b-title">
          <span class="text-one">合规</span>
          <span>分账</span>
        </div>
        <div class="b-des">基于业务交易场景提供资金分账能力，</div>
        <div class="b-des last">助力企业实现安全合规，提高生产效率。</div>
      </div>
      <About></About>
    </div>

    <div class="pain-box">
      <div class="title-box">
        <div class="title">市场痛点</div>
        <div>Market pain points</div>
      </div>
      <div class="content-box">
        <div class="content">
          <div class="text-title b-one">“二清”问题</div>
          <div class="text">存在“二清”合规问题，需要规避并满足平台在线消费、担保交易、多方分账、垫资清算等多种业务场景。
          </div>
        </div>
        <div class="content">
          <div class="text-title b-two">财务效率</div>
          <div class="text">
            信息流、资金流割裂，财务多维度对账困难，需适配企业财务管理流程的在线支付，简化工作流提升核算准确度及效率。
          </div>
        </div>
        <div class="content">
          <div class="text-title b-three">公信力背书</div>
          <div class="text">平台公信力不足，需要利用银行增信，引入交易担保支付机制来提升平台信用，保证资金安全。</div>
        </div>
      </div>
    </div>

    <div class="services-box">
      <div class="title-box">
        <div class="title">我们的服务</div>
        <div>OUR SERVICES</div>
      </div>
      <div class="content-box">
        <div class="content">
          <div>合规分账</div>
          <p>携手多家银行与支付机构，为平台型企业提供集收款、分账、账户管理、资金管理的一站式的合规分账解决方案，统一把控信息流、资金流的双流合一，助力企业合理规避「二清」和资金池风险，为企业及其用户
            的资金安全保驾护航。</p>
        </div>
        <div class="img-b">
          <img alt="" src="../assets/images/c-services-img.png">
        </div>
      </div>
    </div>

    <div class="pain-box-two">
      <div class="title-box">
        <div class="title">市场痛点</div>
        <div>Market pain points</div>
      </div>
      <div class="content-box">
        <div class="content">
          <div class="image-i">
            <img alt="" src="../assets/images/c-pain-icon.png">
          </div>
          <div class="t-box">
            <div class="text-title">“二清”问题</div>
            <div class="text">存在“二清”合规问题，需要规避并满足平台在线消费、担保交易、多方分账、垫资清算等多种业务场景。
            </div>
          </div>
        </div>
        <div class="content">
          <div class="image-i">
            <img alt="" src="../assets/images/c-pain-icon.png">
          </div>
          <div class="t-box">
            <div class="text-title">财务效率</div>
            <div class="text">
              信息流、资金流割裂，财务多维度对账困难，需适配企业财务管理流程的在线支付，简化工作流提升核算准确度及效率。
            </div>
          </div>
        </div>
        <div class="content">
          <div class="image-i">
            <img alt="" src="../assets/images/c-pain-icon.png">
          </div>
          <div class="t-box">
            <div class="text-title">公信力背书</div>
            <div class="text">平台公信力不足，需要利用银行增信，引入交易担保支付机制来提升平台信用，保证资金安全。</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import About from "@/components/About.vue";

export default {
  components: {About, NavBar},
  data() {
    return {};
  },
  mounted() {
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.contain {
  background: #F4F7FC;
}

.banner {
  position: relative;
  background-image: url("../assets/images/compliance-bg1.png");
  background-position: center;
  background-size: cover;
}

.banner-content {
  width: 75%;
  margin: 0 auto;
  padding: 100px 0;
  color: #242424;

  .b-title {
    font-size: 48px;
    margin-bottom: 34px;

    .text-one {
      color: #436CE9;
    }
  }

  .b-des {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
  }

  .last {
    margin-bottom: 150px;
  }
}

.title-box {
  font-size: 20px;
  color: #3370FF;
  text-align: center;
  margin-bottom: 74px;

  .title {
    font-size: 36px;
    color: #1D2129;
    font-weight: bold;
    margin-bottom: 18px;
  }
}

.pain-box {
  padding: 74px 0;

  .content-box {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 70px;

    .content {
      position: relative;
      width: 400px;
      height: 264px;
      background-color: #fff;
      overflow: hidden;

      .text-title {
        font-size: 24px;
        color: #FFFFFF;
        padding: 30px;
        background-position: right;
        background-size: cover;
      }

      .b-one {
        background-image: url("../assets/images/c-pain1.png");
      }

      .b-two {
        background-image: url("../assets/images/c-pain2.png");
      }

      .b-three {
        background-image: url("../assets/images/c-pain3.png");
      }

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        color: #959CAD;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.services-box {
  padding: 74px 0;
  background-image: url("../assets/images/compliance-bg2.png");
  background-size: cover;

  .content-box {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 780px;

      div {
        font-weight: 800;
        font-size: 36px;
        color: #020814;
      }

      p {
        margin-top: 27px;
        font-size: 16px;
        line-height: 32px;
        color: #41464F;
      }
    }

    .img-b {
      width: 513px;
      height: 530px;
    }
  }
}

.pain-box-two {
  padding: 74px 0;

  .content-box {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 70px;

    .content {
      width: 416px;
      height: 154px;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 35px 30px;

      .image-i {
        width: 62px;
        height: 62px;
        padding: 4px;
        background: #FFFFFF;
        border: 1px solid rgba(88, 101, 133, 0.2);
        margin-right: 20px;
      }

      .t-box {
        flex: 1;
      }

      .text-title {
        color: #555555;
        margin-bottom: 8px;
      }

      .text {
        font-size: 10px;
        line-height: 28px;
        color: #999999;
      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .pain-box,.pain-box-two {
    .content-box {
      flex-wrap: wrap;

      .content {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .services-box {
    .content-box {
      flex-wrap: wrap;

      .content {
        width: 100%;
        margin-bottom: 20px;
      }

      .img-b {
        width: 80%;
        height: auto;
        img{
          height: auto !important;
        }
      }
    }
  }

}
</style>