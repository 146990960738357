<template>
 
  <div class="content-docs">
    <div><NavBar></NavBar></div>
    <div style="margin-top:50px;height: 100%;">
    <div class="left-docs" style="height: 700px;">
      <div class="tree">
        <el-tree style="max-width: 600px"
               :data="data"
               :props="defaultProps"
               default-expand-all
               :expand-on-click-node="false"
               ref="ownerTree"
               node-key="id"
               highlight-current
               current-node-key="nodeKey"
               @node-click="handleNodeClick" />
      </div>
    </div>
    <div class="right-docs">
      <h1 id="tongxun">一、通讯方式及报文格式</h1>
      <h2 id="tongxunxieyi">通讯协议</h2>
      <span>商户和本系统之间采用HTTPS/HTTP协议进行通讯。</span>
      <h2 id="qingqiudizhi">请求地址</h2>
      <span>具体与对接人员获取应用主机地址。<br>https://部署应用地址:端口</span>

      <h2 id="qingqiubaowengeshi">报文请求格式</h2>
      <span>Json请求方式<br><v-html></v-html></span>
      <!-- <p><code v-pre class="language-json"><span class="hljs-punctuation">121212</span></code></p> -->
      <div v-highlight>
        <pre class="language-javascript"><code>Content-Type ：application/json</code></pre>
      </div>
      <div v-highlight>
        <pre class="language-javascript"><code>{
    "key":"value",
    "...":"..."
}</code></pre>
      </div>
      <h1 id="baowengeshidingyi">二、报文格式定义</h1>
      <h2 id="fuhaoyueding">符号约定 </h2>
      <el-table :data="tableData"
                border>
        <el-table-column prop="date"
                         width="80px"
                         label="符号" />
        <el-table-column prop="name"
                         width="200px"
                         label="含义" />
      </el-table>
      <h2 id="tongyongqingqiuheader">通用请求Header</h2>
      <el-table :data="Header"
                border>
        <el-table-column prop="date"
                         width="120px"
                         label="代码" />
        <el-table-column prop="name"
                         width="150px"
                         label="中文名称" />
        <el-table-column prop=""
                         width="60px"
                         label="请求" />
        <el-table-column prop=""
                         width="60px"
                         label="描述" />
      </el-table>
      <h2 id="tongyongfanhuibaowengeshi">通用返回报文格式</h2>
      <el-table :data="response"
                border>
        <el-table-column prop="name"
                         width="80px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="150px"
                         label="中文名称" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <h1 id="anquanguifan">三、安全规范</h1>
      <h2 id="qianmingsuanfa">签名算法</h2>
      <span>签名生成的步骤如下： <br /><br />
        设所有发送或者接收到的数据为集合 M，将集合 M 内非空参数值的参数按照参数名 ASCII 码从小到大排序（字典排序），使用 URL 键的值的拼接格式，值与值之间以“|”分隔（即 <font style="color: red;">value1|value2|value3…</font>）拼接成字符串 stringA。
      </span>
      <span>特别注意以下重要规则：<br/><br/></span>
      <p>◆ 必须加上商户号merchantNo；</p>
      <p>◆ 参数名按 ASCII 码从小到大排序（字典排序）；</p>
      <p>◆ 如果参数的值为空不参与签名；也就是说参数是空的不需要参与签名；</p>
      <p>◆ 参数名区分大小写；</p>
      <p>◆ 生成的签名需转成大写；</p>
      <p>◆ 验证调用返回或主动通知签名时，传送的 sign 参数不参与签名，将生成的签名与该 sign 值作校验；</p>
      <p>◆ 接口可能增加字段，验证签名时必须支持增加的扩展字段。</p>
      <p>String sign = MD5.encode(<font style="color: red;">stringA|密钥key</font>) 转大写。</p>

      <h1 id="jiekoushuoming">四、接口说明</h1>
      <!-- 统一下单 -->
      <h2 id="tongyixiadan">统一下单</h2>
      <span>功能描述 <br /><br />
        为商户提供收银台服务，支持多种支付方式。商户请求收银台统一下单接口后，可以直接跳转至收银台页面，用户在收银台页面完成后续支付流程，支付完成会跳转回到商户页面（对接时商户指定的页面地址）。
      </span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/unifiedOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="orderReq"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="210px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="length"
                         width="60px"
                         label="长度" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="210px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="orderResponse"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="210px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 支付结果通知 -->
      <h2 id="zhifujieguotongzhi">支付结果通知</h2>
      <span>功能描述 <br /><br />通知策略：收到回调通知需回写大写“SUCCESS”，如没有回写则最多通知 9次，重试延迟时间分别为：5,5,20,270,600,900,1800,3600,14400（秒），9次后没有拿到回写则停止通知。 查看更多规则</span>
      <p>接入结果通知的同时，请务必接入查单接口。当一笔订单没有收到结果通知时，若通知超时时间已超过您的系统容忍量，您可以主动调用查单接口查询订单状态。</p>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>GET 商户支付结果通知地址>>notifyUrl</code></pre>
      </div>
      <p>返回报文</p>
      <el-table :data="orderNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="180px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 支付订单查询 -->
      <h2 id="zhifudingdanchaxun">支付订单查询</h2>
      <span>功能描述 <br /><br />商户可以通过查询订单接口主动查询订单状态，完成下一步的业务逻辑。。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/queryOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="queryOrderReq"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <p>data数据域</p>
      <el-table :data="orderNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 退款申请 -->
      <h2 id="tuikuanshenqing">退款申请</h2>
      <span>功能描述 <br /><br />
      <p>1、退款支持单笔交易分多次退款，多次退款需要提交原支付订单的商户订单号和不同的退款单号，总退款金额不能超过用户实际支付金额。</p>
      <p>2、 一笔退款失败后重新提交，请不要更换退款单号，请使用相同的商户退款请求号请求退款。</p></span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/refundOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="refundOrderReq"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <p>data数据域</p>
      <el-table :data="refundOrderResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      
      <!-- 退款结果通知 -->
      <h2 id="tuikuanjieguotongzhi">退款结果通知</h2>
      <span>功能描述 <br /><br />通知策略：收到回调通知需回写大写“SUCCESS”，如没有回写则最多通知 9次，重试延迟时间分别为：5,5,20,270,600,900,1800,3600,14400（秒），9次后没有拿到回写则停止通知。 查看更多规则</span>
      <p>接入结果通知的同时，请务必接入查单接口。当一笔订单没有收到结果通知时，若通知超时时间已超过您的系统容忍量，您可以主动调用查单接口查询订单状态。</p>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>GET 商户支付结果通知地址>>notifyUrl</code></pre>
      </div>
      <p>返回报文</p>
      <el-table :data="refundOrderNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="140px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 退款订单查询 -->
      <h2 id="tuikuandingdanchaxun">退款订单查询</h2>
      <span>功能描述 <br /><br />提交退款申请后，通过调用该接口查询退款状态。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/queryRefundOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="queryRefundOrderReq"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="refundOrderNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 代付申请 -->
      <h2 id="daifushenqing">代付申请</h2>
      <span>功能描述 <br /><br />通过此服务通过商户向已添加的固定收款方付款的能力。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/transferOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="payOrderReq"
                border>
        <el-table-column prop="name"
                         width="170px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="payOrderResponse"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="120px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
  
      <!-- 代付结果通知 -->
      <h2 id="daifujieguotongzhi">代付结果通知</h2>
      <span>功能描述 <br /><br />通知策略：收到回调通知需回写大写“SUCCESS”，如没有回写则最多通知 9次，重试延迟时间分别为：5,5,20,270,600,900,1800,3600,14400（秒），9次后没有拿到回写则停止通知。 查看更多规则</span>
      <p>接入结果通知的同时，请务必接入查单接口。当一笔订单没有收到结果通知时，若通知超时时间已超过您的系统容忍量，您可以主动调用查单接口查询订单状态。</p>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>GET 商户代付结果通知地址>>notifyUrl</code></pre>
      </div>
      <p>返回报文</p>
      <el-table :data="payNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="140px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 代付订单查询 -->
      <h2 id="daifudingdanchaxun">代付订单查询</h2>
      <span>功能描述 <br /><br />提交代付申请后，通过调用该接口查询代付订单状态。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/queryTransferOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="queryPayOrderReq"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="payNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 代付申请 -->
      <!-- <h2>代付申请</h2>
      <span>功能描述 <br /><br />通过此服务通过商户向已添加的固定收款方付款的能力。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/transferOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="payOrderReq"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="payOrderResponse"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="120px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table> -->
      
      <!-- 提现申请 -->
      <h2  id="tixianshenqing">提现申请</h2>
      <span>功能描述 <br /><br />提供商户将资金从商户账户提至商户关联的银行卡的能力。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/withdrawOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="withdrawOrderReq"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="withdrawOrderResponse"
                border>
        <el-table-column prop="name"
                         width="120px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="120px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      
      <!-- 提现结果通知 -->
      <h2  id="tixianjieguotongzhi">提现结果通知</h2>
      <span>功能描述 <br /><br />通知策略：收到回调通知需回写大写“SUCCESS”，如没有回写则最多通知 9次，重试延迟时间分别为：5,5,20,270,600,900,1800,3600,14400（秒），9次后没有拿到回写则停止通知。 查看更多规则</span>
      <p>接入结果通知的同时，请务必接入查单接口。当一笔订单没有收到结果通知时，若通知超时时间已超过您的系统容忍量，您可以主动调用查单接口查询订单状态。</p>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>GET 商户代付结果通知地址>>notifyUrl</code></pre>
      </div>
      <p>返回报文</p>
      <el-table :data="withdrawNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="140px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 提现订单查询 -->
      <h2 id="tixiandingdanchaxun">提现订单查询</h2>
      <span>功能描述 <br /><br />提供商户通过该接口查询提现订单。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/queryWithdrawOrder</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="queryPayOrderReq"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="310px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="withdrawNotifyResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 余额查询 -->
      <h2 id="yuechaxun">余额查询</h2>
      <span>功能描述 <br /><br />提供商户通过该接口查询余额信息。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/getAccountBalance</code></pre>
      </div>
      <p>请求参数</p>
      <el-table border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="queryBalanceResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>

      <!-- 上报收款卡 -->
      <h2 id="shoukuankabangding">上报收款卡</h2>
      <span>功能描述 <br /><br />提供商户通过该接扣提交收款卡。</span>
      <p>请求地址</p>
      <div v-highlight>
        <pre class="language-javascript"><code>POST /pay/txn/bindPayCard</code></pre>
      </div>
      <p>请求参数</p>
      <el-table :data="bindingReq" border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="220px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>返回报文</p>
      <el-table :data="allResponse"
                border>
        <el-table-column prop="name"
                         width="130px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="100px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      <p>data数据域</p>
      <el-table :data="queryBalanceResponse"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
        <el-table-column prop="type"
                         width="150px"
                         label="类型" />
        <el-table-column prop="req"
                         width="60px"
                         label="请求" />
        <el-table-column prop="remark"
                         width="225px"
                         label="描述" />
      </el-table>
      
      <h1 id="fulu">五、附录（数据字典）</h1>
      <!-- 状态码 -->
      <h2 id="zhuangtaima">状态码</h2>
      <el-table :data="statusCode"
                border>
        <el-table-column prop="name"
                         width="150px"
                         label="code" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
      </el-table>

      <!-- 交易类型 -->
      <h2 id="jiaoyileixing">交易类型</h2>
      <el-table :data="payType"
                border>
        <el-table-column prop="name"
                         width="205px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="160px"
                         label="中文名称" />
      </el-table>

      <!-- 支付订单状态 -->
      <h2 id="zhifudingdanzhuangtai">支付订单状态</h2>
      <el-table :data="paymentStatus"
                border>
        <el-table-column prop="name"
                         width="160px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="270px"
                         label="中文名称" />
      </el-table>

      <!-- 退款订单状态 -->
      <h2 id="tuikuandingdanzhuangtai">退款订单状态</h2>
      <el-table :data="refundStatus"
                border>
        <el-table-column prop="name"
                         width="200px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         label="中文名称" />
      </el-table>

      <!-- 代付账户类型 -->
      <h2 id="daifuzhanghuleixing">代付账户类型</h2>
      <el-table :data="payAccountType"
                border>
        <el-table-column prop="name"
                         width="180px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="140px"
                         label="中文名称" />
      </el-table>

      <!-- 提现账户类型 -->
      <h2 id="tixianzhanghuleixing">提现账户类型</h2>
      <el-table :data="withdrawAccountType"
                border>
        <el-table-column prop="name"
                         width="180px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="140px"
                         label="中文名称" />
      </el-table>

      <!-- 银行卡类型 -->
      <h2 id="yinhangkaleixing">银行卡类型</h2>
      <el-table :data="bankCardType"
                border>
        <el-table-column prop="name"
                         width="210px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         width="100px"
                         label="中文名称" />
      </el-table>
      
      <!-- 提现订单状态 -->
      <h2 id="tixiandingdanzhuangtai">提现订单状态</h2>
      <el-table :data="withdrawStatus"
                border>
        <el-table-column prop="name"
                         width="180px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         label="中文名称" />
      </el-table>
      
      
      <!-- 付款订单状态 -->
      <h2 id="fukuandingdanzhuangtai">付款订单状态</h2>
      <el-table :data="payStatus"
                border>
        <el-table-column prop="name"
                         width="180px"
                         label="属性名称" />
        <el-table-column prop="cname"
                         label="中文名称" />
      </el-table>
    </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import NavBar from "@/components/NavBar.vue"
import md from '../assets/result_KDLzw.md'
import 'highlight.js/styles/github.css'
export default {
  data () {
    return {
      markdown: '# Hello, Vue Markdown!',
      defaultProps: {
        children: 'children',
        label: 'label',
        class: 'id',
      },
      data: [
        {
          label: '一、通讯方式及报文格式',
          children: [
            {
              label: '通讯协议',
              id: 'tongxunxieyi'
            },
            {
              label: '请求地址',
              id: 'qingqiudizhi'
            },
            {
              label: '请求报文格式',
              id: 'qingqiubaowengeshi'
            },
          ],
          id: 'tongxun'
        },
        {
          label: '二、报文格式定义',
          id: 'baowengeshidingyi',
          children: [
            {
              label: '符号约定',
              id: 'fuhaoyueding'
            },
            {
              label: '通用请求Header',
              id: 'tongyongqingqiuheader'
            },
            {
              label: '通用返回报文格式',
              id: 'tongyongfanhuibaowengeshi'
            },
          ],
        },
        {
          label: '三、安全规范',
          id: '安全规范',
          children: [
            {
              label: '签名算法',
              id: 'qianmingsuanfa'
            }
          ],
        },
        {
          label: '四、接口说明',
          id: 'jiekoushuoming',
          children: [
            {
              label: '统一下单',
              id: 'tongyixiadan'
            },
            {
              label: '支付结果通知',
              id: 'zhifujieguotongzhi'
            },
            {
              label: '支付订单查询',
              id: 'zhifudingdanchaxun'
            },
            {
              label: '退款申请',
              id: 'tuikuanshenqing'
            },
            {
              label: '退款结果通知',
              id: 'tuikuanjieguotongzhi'
            },
            {
              label: '退款订单查询',
              id: 'tuikuandingdanchaxun'
            },
            {
              label: '代付申请',
              id: 'daifushenqing'
            },
            {
              label: '代付结果通知',
              id: 'daifujieguotongzhi'
            },
            {
              label: '代付订单查询',
              id: 'daifudingdanchaxun'
            },
            {
              label: '提现申请',
              id: 'tixianshenqing'
            },
            {
              label: '提现结果通知',
              id: 'tixianjieguotongzhi'
            },
            {
              label: '提现订单查询',
              id: 'tixiandingdanchaxun'
            },
            {
              label: '余额查询',
              id: 'yuechaxun'
            },
            {
              label: '上报收款卡',
              id: 'shoukuankabangding'
            }
          ],
        },
        {
          label: '五、附录（数据字典）',
          id: 'fulu',
          children: [
            {
              label: '状态码',
              id: 'zhuangtaima'
            },
            {
              label: '交易类型',
              id: 'jiaoyileixing'
            },
            {
              label: '支付订单状态',
              id: 'zhifudingdanzhuangtai'
            },
            {
              label: '退款订单状态',
              id: 'tuikuandingdanzhuangtai'
            },
            {
              label: '代付账户类型',
              id: 'daifuzhanghuleixing'
            },
            {
              label: '提现账户类型',
              id: 'tixianzhanghuleixing'
            },
            {
              label: '银行卡类型',
              id: 'yinhangkaleixing'
            },
            {
              label: '提现订单状态',
              id: 'tixiandingdanzhuangtai'
            },
            {
              label: '付款订单状态',
              id: 'fukuandingdanzhuangtai'
            }
          ],
        }
      ],
      tableData: [{
        date: 'M',
        name: '必须填写的域',
      },
      {
        date: 'C',
        name: '某条件成立时必须填写的域',
      },
      {
        date: 'O',
        name: '自选填写的域',
      }],
      Header: [{
        date: 'merchantNo',
        name: '商户编号',
      },
      {
        date: 'secretKey',
        name: '平台密钥',
      }],
      response: [{
        name: 'code',
        cname: '返回状态码',
        req: 'M',
        remark: '状态码正常：200，异常：非200',
      },
      {
        name: 'msg',
        cname: '错误信息',
        req: 'M',
        remark: '',
      },
      {
        name: 'data',
        cname: '返回数据对象',
        req: 'C',
        remark: '',
      }],
      orderReq: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'orderAmount',
        cname: '订单金额',
        type: 'BigDecimal',
        length: '10,2',
        req: 'M',
        remark: '',
      },
      {
        name: 'goodsName',
        cname: '商品名称',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'notifyUrl',
        cname: '支付结果通知地址',
        type: 'String',
        length: '200',
        req: 'M',
        remark: '',
      },
      {
        name: 'returnUrl',
        cname: '页面回调地址',
        type: 'String',
        length: '200',
        req: 'O',
        remark: '',
      },
      {
        name: 'businessInfo',
        cname: '自定义参数信息（json格式）',
        type: 'String',
        length: '1024',
        req: 'O',
        remark: '',
      },
      {
        name: 'tradeType',
        cname: '交易类型',
        type: 'String',
        length: '50',
        req: 'M',
        remark: '详见附录：交易类型',
      },
      {
        name: 'openId',
        cname: '小程序、公众号openId',
        type: 'String',
        length: '100',
        req: 'C',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      allResponse: [{
        name: 'code',
        cname: '返回状态码',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'msg',
        cname: '错误信息',
        type: 'String',
        req: 'O',
        remark: '失败返回消息',
      },
      {
        name: 'data',
        cname: '返回数据对象',
        type: 'Object',
        req: 'O',
        remark: '',
      }],
      orderResponse: [{
        name: 'payUrl',
        cname: '支付地址',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'channelOrderNo',
        cname: '平台订单号',
        type: 'String',
        req: 'O',
        remark: '',
      }],
      orderNotifyResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'status',
        cname: '订单状态',
        type: 'String',
        req: 'O',
        remark: '详见附录：支付订单状态',
      },
      {
        name: 'orderAmount',
        cname: '订单金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'payAmount',
        cname: '实际支付金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '',
      },
      {
        name: 'paySuccessDate',
        cname: '支付成功时间',
        type: 'String',
        req: 'C',
        remark: '',
      },
      {
        name: 'orderFee',
        cname: '订单手续费',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '',
      },
      {
        name: 'tradeType',
        cname: '交易类型',
        type: 'String',
        req: 'O',
        remark: '详见附录：交易类型',
      }],
      queryOrderReq: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      refundOrderReq: [{
        name: 'orderNo',
        cname: '原支付订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'refundOrderNo',
        cname: '退款订单号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'refundAmount',
        cname: '退款金额',
        type: 'BigDecimal',
        length: '10,2',
        req: 'M',
        remark: '',
      },
      {
        name: 'description',
        cname: '退款原因',
        type: 'String',
        length: '200',
        req: 'O',
        remark: '',
      },
      {
        name: 'notifyUrl',
        cname: '退款结果通知地址',
        type: 'String',
        length: '200',
        req: 'O',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      refundOrderResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'refundOrderNo',
        cname: '退款订单号',
        type: 'String',
        req: 'O',
        remark: '',
      },
      {
        name: 'refundAmount',
        cname: '退款金额',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'status',
        cname: '退款订单状态	',
        type: 'String',
        req: 'O',
        remark: '详见附录：退款订单状态',
      }],
      refundOrderNotifyResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'refundOrderNo',
        cname: '退款订单号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'status',
        cname: '退款订单状态',
        type: 'String',
        req: 'O',
        remark: '详见附录：退款订单状态',
      },
      {
        name: 'refundAmount',
        cname: '退款订单金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'description',
        cname: '退款原因',
        type: 'String',
        req: 'O',
        remark: '',
      },
      {
        name: 'refundSuccessDate',
        cname: '退款成功时间',
        type: 'String',
        req: 'C',
        remark: '',
      }],
      queryRefundOrderReq: [{
        name: 'orderNo',
        cname: '原支付订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'refundOrderNo',
        cname: '退款订单号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      payOrderReq: [{
        name: 'supplierId',
        cname: '供应商ID',
        type: 'Long',
        length: '',
        req: 'C',
        remark: '',
      },
      {
        name: 'orderNo',
        cname: '付款订单号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'idCardNo',
        cname: '收款方身份证号',
        type: 'String',
        length: '30',
        req: 'M',
        remark: '',
      },
      {
        name: 'phoneNumber',
        cname: '收款方手机号',
        type: 'String',
        length: '20',
        req: 'O',
        remark: '',
      },
      {
        name: 'bankCode',
        cname: '开户银行编码',
        type: 'String',
        length: '100',
        req: 'O',
        remark: '',
      },
      {
        name: 'receiverAccountName',
        cname: '收款方开户名',
        type: 'String',
        length: '50',
        req: 'M',
        remark: '',
      },
      {
        name: 'receiverAccountNo',
        cname: '收款方银行账号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'receiverBankName',
        cname: '收款方银行名称',
        type: 'String',
        length: '100',
        req: 'O',
        remark: '',
      },
      {
        name: 'orderAmount',
        cname: '付款金额',
        type: 'BigDecimal',
        length: '10,2',
        req: 'M',
        remark: '',
      },
      {
        name: 'accountType',
        cname: '账户类型',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '详见附录：账户类型',
      },
      {
        name: 'explain',
        cname: '付款说明',
        type: 'String',
        length: '200',
        req: 'M',
        remark: '',
      },
      {
        name: 'payPurpose',
        cname: '付款用途',
        type: 'String',
        length: '100',
        req: 'O',
        remark: '',
      },
      {
        name: 'notifyUrl',
        cname: '付款结果通知地址',
        type: 'String',
        length: '200',
        req: 'O',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      payOrderResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'orderAmount',
        cname: '付款金额',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'status',
        cname: '付款订单状态	',
        type: 'String',
        req: 'O',
        remark: '详见附录：付款订单状态',
      }],
      payNotifyResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'status',
        cname: '付款订单状态',
        type: 'String',
        req: 'O',
        remark: '详见附录：付款订单状态',
      },
      {
        name: 'orderAmount',
        cname: '付款订单金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'supplierId',
        cname: '供应商ID',
        type: 'String',
        req: 'C',
        remark: '',
      },
      {
        name: 'finishTime',
        cname: '完成时间',
        type: 'String',
        req: 'C',
        remark: '',
      }],
      queryPayOrderReq: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      withdrawOrderReq: [{
        name: 'orderNo',
        cname: '提现订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'orderAmount',
        cname: '提现订单金额',
        type: 'BigDecimal',
        length: '10,2',
        req: 'M',
        remark: '',
      },
      {
        name: 'receiverAccountName',
        cname: '收款方开户名',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'receiverAccountNo',
        cname: '收款方银行账号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'notifyUrl',
        cname: '提现结果通知地址',
        type: 'String',
        length: '200',
        req: 'O',
        remark: '',
      },
      {
        name: 'remark',
        cname: '银行附言',
        type: 'String',
        length: '200',
        req: 'C',
        remark: '',
      },
      {
        name: 'accountType',
        cname: '账户类型',
        type: 'String',
        length: '100',
        req: 'C',
        remark: '详见附录：账户类型',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      withdrawOrderResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'orderAmount',
        cname: '付款金额',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'status',
        cname: '付款订单状态	',
        type: 'String',
        req: 'O',
        remark: '详见附录：提现订单状态',
      }],
      withdrawNotifyResponse: [{
        name: 'orderNo',
        cname: '订单编号',
        type: 'String',
        req: 'M',
        remark: '',
      },
      {
        name: 'status',
        cname: '提现订单状态',
        type: 'String',
        req: 'O',
        remark: '详见附录：提现订单状态',
      },
      {
        name: 'orderAmount',
        cname: '提现订单金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'feeAmount',
        cname: '提现手续费(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'finishTime',
        cname: '提现完成时间',
        type: 'String',
        req: 'C',
        remark: '示例值：2024-06-01 01:00:00',
      }],
      queryPayOrderReq: [{
        name: 'orderNo',
        cname: '提现订单编号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'O',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      queryBalanceResponse: [{
        name: 'balance',
        cname: '可用余额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      },
      {
        name: 'creditedAmount',
        cname: '待入账金额(元)',
        type: 'BigDecimal(10,2)',
        req: 'O',
        remark: '保留2位小数',
      }],
      bindingReq: [{
        name: 'bankName',
        cname: '银行名称',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'bankCreditNo',
        cname: '银行卡号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'phone',
        cname: '银行预留手机号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'cardType',
        cname: '证件类型(默认传1)',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'accountType',
        cname: '账户类型(详见附录：账户类型)',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'payeeName',
        cname: '收款人姓名',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'idCardNo',
        cname: '持卡人证件号',
        type: 'String',
        length: '100',
        req: 'M',
        remark: '',
      },{
        name: 'supplierId',
        cname: '供应商ID(不参与签名)',
        type: 'String',
        length: '100',
        req: 'O',
        remark: '',
      },{
        name: 'bankCode',
        cname: '联行号(不参与签名)',
        type: 'String',
        length: '100',
        req: 'O',
        remark: '',
      },
      {
        name: 'sign',
        cname: '签名字符串',
        type: 'String',
        length: '100',
        req: 'M',
        remark: 'MD5加密签名（大写）签名规则参考安全规范',
      }],
      statusCode: [{
        name: "200",
        cname: "成功"
      },
      {
        name: "非200",
        cname: "失败"
      }],
      payType: [{
        name: "1_MINI_PROGRAM",
        cname: "小程序"
      },
      {
        name: "1_WECHAT_OFFIACCOUNT",
        cname: "微信公众号"
      },
      {
        name: "2_ALIPAY_NATIVE",
        cname: "支付宝扫码(主扫)"
      },
      {
        name: "2_ALIPAY_CARD",
        cname: "支付宝刷卡(被扫)"
      },
      {
        name: "2_ALIPAY_H5",
        cname: "支付宝H5"
      },
      {
        name: "2_ALIPAY_FWC",
        cname: "支付宝服务窗"
      },
      {
        name: "2_ALIPAY_SYT",
        cname: "支付宝收银台"
      },
      {
        name: "2_WEIXIN_NATIVE",
        cname: "微信扫码(主扫)"
      },
      {
        name: "2_WEIXIN_CARD",
        cname: "微信刷卡(被扫)"
      },
      {
        name: "2_WEIXIN_APP3",
        cname: "微信APP+支付"
      },
      {
        name: "2_WEIXIN_H5_PLUS",
        cname: "微信H5支付"
      },
      {
        name: "2_WEIXIN_GZH",
        cname: "微信公众号支付"
      },
      {
        name: "2_WEIXIN_XCX",
        cname: "微信小程序支付"
      },
      {
        name: "2_UNIONPAY_NATIVE",
        cname: "银联扫码(主扫)"
      },
      {
        name: "2_UNIONPAY_CARD",
        cname: "银联刷卡(被扫)"
      },
      {
        name: "2_UNIONPAY_APP",
        cname: "银联APP支付"
      },
      {
        name: "2_UNIONPAY_SYT",
        cname: "银联统一收银台"
      },
      {
        name: "2_UNIONPAY_H5",
        cname: "银联H5"
      },
      {
        name: "3_OFFLINE_PAYMENT",
        cname: "当面付快捷版"
      },
      {
        name: "3_FACE_TO_FACE_PAYMENT",
        cname: "其它支付宝当面付"
      },
      {
        name: "4_JSAPI",
        cname: "JSAPI支付"
      },
      {
        name: "4_NATIVE",
        cname: "Native支付"
      },
      {
        name: "4_APP",
        cname: "app支付"
      },
      {
        name: "4_MWEB",
        cname: "H5支付"
      }],
      paymentStatus: [{
        name: "1_PROCESSING",
        cname: "订单待支付"
      },
      {
        name: "1_SUCCESS",
        cname: "订单支付成功"
      },
      {
        name: "1_TIME_OUT",
        cname: "订单已过期"
      },
      {
        name: "1_FAIL",
        cname: "订单支付失败"
      },
      {
        name: "1_CLOSE",
        cname: "订单关闭"
      },
      {
        name: "2_100",
        cname: "支付成功"
      },
      {
        name: "2_101",
        cname: "支付失败"
      },
      {
        name: "2_102",
        cname: "待支付"
      },
      {
        name: "3_TRADE_CLOSED",
        cname: "交易关闭"
      },
      {
        name: "3_TRADE_FINISHED",
        cname: "交易完结"
      },
      {
        name: "3_TRADE_SUCCESS",
        cname: "支付成功"
      },
      {
        name: "3_WAIT_BUYER_PAY",
        cname: "交易创建"
      },
      {
        name: "4_SUCCESS",
        cname: "支付成功"
      },
      {
        name: "4_REFUND",
        cname: "转入退款"
      },
      {
        name: "4_NOTPAY",
        cname: "未支付"
      },
      {
        name: "4_CLOSED",
        cname: "已关闭"
      },
      {
        name: "4_REVOKED",
        cname: "已撤销（付款码支付）"
      },
      {
        name: "4_USERPAYING",
        cname: "用户支付中（付款码支付）"
      },
      {
        name: "4_PAYERROR",
        cname: "支付失败(其他原因，如银行返回失败)"
      }],
      refundStatus: [{
        name: "1_PROCESSING",
        cname: "退款处理中"
      },
      {
        name: "1_SUCCESS",
        cname: "退款成功"
      },{
        name: "1_FAILED",
        cname: "退款失败"
      },
      {
        name: "1_CANCEL",
        cname: "退款关闭,商户通知结束该笔退款后返回该状态"
      },{
        name: "1_SUSPEND",
        cname: "退款中断,如需继续退款,请调用上送卡信息退款进行打款退款;如想结束退款,请调用结束退款来关闭退款订单"
      },
      {
        name: "2_100",
        cname: "退款成功"
      },{
        name: "2_101",
        cname: "退款失败"
      },
      {
        name: "3_REFUND_SUCCESS",
        cname: "退款关闭"
      },{
        name: "非3_REFUND_SUCCESS",
        cname: "退款失败"
      },
      {
        name: "4_SUCCESS",
        cname: "退款成功"
      },{
        name: "4_CLOSED",
        cname: "退款关闭"
      },
      {
        name: "4_ABNORMAL",
        cname: "退款异常，退款到银行发现用户的卡作废或者冻结了，导致原路退款银行卡失败"
      }],
      payAccountType: [{
        name: "1_DEBIT_CARD",
        cname: "借记卡"
      },
      {
        name: "1_CREDIT_CARD	",
        cname: "贷记卡"
      },
      // ,{
      //   name: "1_QUASI_CREDIT_CARD",
      //   cname: "准贷卡"
      // },
      // {
      //   name: "1_PASSBOOK",
      //   cname: "存折"
      // },{
      //   name: "1_UNIT_SETTLE_CARD",
      //   cname: "单位结算卡"
      // },
      {
        name: "1_PUBLIC_CARD",
        cname: "对公卡"
      }
      // ,{
      //   name: "2_100",
      //   cname: "提现成功"
      // },
      // {
      //   name: "2_101",
      //   cname: "提现失败"
      // }
    ],
      withdrawAccountType: [{
        name: "1_FUND_ACCOUNT",
        cname: "资金账户"
      },
      {
        name: "1_MARKET_ACCOUNT",
        cname: "营销账户"
      },
      {
        name: "1_FEE_ACCOUNT",
        cname: "手续费账户"
      }],
      bankCardType: [{
        name: "1_DEBIT_CARD",
        cname: "借记卡"
      },
      {
        name: "1_ENTERPRISE_ACCOUNT",
        cname: "对公账号"
      },
      {
        name: "1_UNIT_SETTLEMENT_CARD",
        cname: "单位结算卡"
      }],
      withdrawStatus: [{
        name: "1_REQUEST_RECEIVE",
        cname: "请求已接收（正在处理中，收到最终结果前请勿重复下单）"
      },
      {
        name: "1_REQUEST_ACCEPT",
        cname: "请求已受理（正在处理中，收到最终结果前请勿重复下单）"
      },
      {
        name: "1_SUCCESS",
        cname: "已到账"
      },
      {
        name: "1_FAIL",
        cname: "失败（该笔订单付款失败,可重新发起付款）"
      },
      {
        name: "1_REMITING",
        cname: "银行处理中（银行正在处理中，收到最终结果前请勿重复下单）"
      },
      {
        name: "2_100",
        cname: "提现成功"
      },
      {
        name: "2_101",
        cname: "提现失败"
      }],
      nodeKey: '',
      payStatus: [{
        name: "2_201",
        cname: "订单已创建"
      },
      {
        name: "2_202",
        cname: "待商户审核"
      },
      {
        name: "2_203",
        cname: "交易处理中"
      },
      {
        name: "2_204",
        cname: "交易失败"
      },
      {
        name: "2_205",
        cname: "交易成功"
      },
      {
        name: "2_208",
        cname: "订单已取消"
      },
      {
        name: "2_210",
        cname: "账务冻结中"
      },
      {
        name: "2_211",
        cname: "账务解冻中"
      },
      {
        name: "2_212",
        cname: "订单取消中"
      },
      {
        name: "2_213",
        cname: "账务扣款中"
      },
      {
        name: "2_214",
        cname: "订单不存在"
      }],
    }
  },
  components: {
    VueMarkdown,
    md,
    NavBar
  },
  methods: {
    handleNodeClick(node,e) {
      this.nodeKey = node.id
    }
  },
  watch:{
    nodeKey(val) {
      // console.log(val)
      const returnEle = document.querySelector("#"+val);  //productId是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
    }
  }
}
</script>

<style>
h1,
h2,
h3 {
  border-bottom: 1px solid gray;
}

pre {
  width: 20vw;
}
.right-doc .left-docs {
  position: absolute;
  overflow: scroll;
}

.right-docs {  
        /* height: 2000px; */
		    padding-left: 200px; /* 留出左侧div的空间 */  
        overflow-y: auto;  
		    padding: 300px; 
        margin-top: -1000px;
        z-index: 1;
		  }


      .left-docs {  
        position: -webkit-sticky; 
        position: sticky;
		    left: 0;  
		    top: 5vw;  
		    width: 200px; 
		    background-color: #f0f0f0; 
		    overflow-y: auto;  
        z-index: 2;
		  }

      /* .content-docs {
        display: flex;
      } */

</style>