// import { createApp } from 'vue'
// import App from './App.vue'
//
// createApp(App).mount('#app')

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import './assets/css/global.css'
import '@/utils/rem.js'
import VueCodeHighlight from 'vue-code-highlight';
import 'vue-code-highlight/themes/prism-coy.css'
Vue.use(VueCodeHighlight) 

Vue.config.productionTip = false

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
Vue.use(ElementUI)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
