<template>
  <div class="">
    <div class="banner">
      <NavBar :isBlack="false"></NavBar>
      <div class="banner-content">
        <div class="b-title">交易分账，优选聚亿</div>
        <div class="b-des">助力企业合规高效经营</div>
        <div class="consult"><a href="#link">立即咨询</a></div>
      </div>
      <About></About>
    </div>

    <div class="advantages-box">
      <div class="title-box">
        <div class="title">我们的优势，远不止于此</div>
        <div>Our advantages</div>
      </div>
      <div class="content-box">
        <div class="content">
          <div class="left">
            <div class="big-t">交易支付服务商</div>
            <div>提供交易数字化解决方案</div>
            <div class="type">
              <div>合规分账</div>
              <div>聚合支付</div>
              <div class="type-item">让交易更有价值</div>
            </div>
            <div class="consult">立即咨询</div>
          </div>
          <div class="right">
            <div class="title">聚在亿起（厦门）科技有限公司</div>
            <p>聚亿是一家交易支付服务商，致力于为广大客户提供交易数字化解决方案。公司于2023年成立，创始团队拥有超过10年的支付行业从业经验，具备丰富的专业知识和技能，希望将其多年的支付行业沉淀资源赋能于客户的业务转型，这也是公司成立的初衷。 公司的文化是“聚人成事，创造价值”，我们相信团队的凝聚力和合作精神是D取得成功的关键。为客户提供专业极致的服务是我们的优势，我们致力于满足客户的需求并超越他们的期望。除了为客户提供量身定制的解决方案，我们还在努力吸引各方面的人才和合作伙伴。 公司希望通过“聚亿”平台，搭建一个向上、向善的生态服务圈，为行业的发展，客户的转型，合作伙伴的共赢做出贡献。在聚在亿起（厦门）科技有限公司，我们坚信只有通过团结合作、专业服务和不断创新，才能实现合作伙伴和公司的共同成功。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="business-box">
      <div class="title-box">
        <div class="title">我们的优势，远不止于此</div>
        <div>Our advantages</div>
      </div>
      <div class="content-box">
        <div class="business card-one">
          <div class="title">交易分账</div>
          <div class="content">
            <div class="des">携手多家银行，为企业提供合规、专业、安全的服务，精确匹配分账业务场景诉求，为企业合规交易保驾护航</div>
            <div class="tag">
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                合规
              </div>
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                专业
              </div>
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                安全
              </div>
            </div>
          </div>
          <div class="button">免费试用</div>
        </div>
        <div class="business card-two">
          <div class="title">聚合支付</div>
          <div class="content">
            <div class="des">企业业务全场景的覆盖，从根本上解决渠道繁多、接入成本高和业务复杂、财务管理难的普遍问题，让企业轻松拥有交易数字化解决方案，一站式高效管理交易数据。</div>
            <div class="tag">
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                全场景覆盖
              </div>
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                高效管理
              </div>
              <div>
                <span>
                  <img src="../assets/images/right.png" alt="">
                </span>
                自动化
              </div>
            </div>
          </div>
          <div class="button">免费试用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import About from "@/components/About.vue";

export default {
  components: {About, NavBar},
  data() {
    return {};
  },
  mounted() {
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  background-image: url("../assets/images/home-bg1.png");
  background-position: right;
  background-size: cover;
}

.banner-content {
  width: 75%;
  margin: 0 auto;
  padding: 100px 0;
  color: #fff;

  .b-title {
    font-size: 48px;
  }

  .b-des {
    font-size: 24px;
    margin-top: 23px;
  }

  .consult {
    width: 144px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    color: #0580FF;
    text-align: center;
    line-height: 48px;
    margin-top: 40px;
    margin-bottom: 130px;
  }
}

.title-box {
  font-size: 20px;
  color: #3370FF;
  text-align: center;
  margin-bottom: 74px;

  .title {
    font-size: 36px;
    color: #1D2129;
    font-weight: bold;
    margin-bottom: 18px;
  }
}

.advantages-box {
  padding: 74px 0;
  background: #F4F7FC;

  .content-box {
    background-image: url("../assets/images/home-bg2.png");
    background-size: cover;
  }

  .content {
    padding: 111px 0;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .left {
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 30px;

      > div {
        margin-top: 12px;
      }

      .big-t {
        font-size: 30px;
        margin-top: 0;
      }

      .type {
        padding: 10px 0;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        font-weight: 400;
        width: 336px;
        height: 178px;
        >div{
          margin-bottom: 10px;
          padding: 10px 20px;
        }
        .type-item{
          background: rgba(255,255,255,0.2);
          border-radius: 0px 4px 4px 0px;
          padding: 5px 20px;
          margin-bottom: 30px;
        }
      }

      .consult {
        width: 113px;
        height: 44px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FFFFFF;
        text-align: center;
        font-size: 16px;
        line-height: 44px;
      }
    }

    .right{
      width: 1072px;
      min-height: 316px;
      background: rgba(255,255,255,0.9);
      box-shadow: 0px 4px 60px 0px rgba(0,28,85,0.04);
      border-radius: 8px 8px 8px 8px;
      padding: 32px;
      font-size: 14px;
      color: #41464F;
      line-height: 32px;
      .title{
        font-weight: 800;
        font-size: 18px;
        line-height: 26px;
        color: #020814;
        margin-bottom: 18px;
        margin-top: 16px;
      }
    }
  }
}

.business-box{
  padding: 74px 0;
  background-image: url("../assets/images/home-bg3.png");
  background-size: cover;
  .content-box{
    display: flex;
    justify-content: center;
    flex-direction: row;
    .card-one{
      background-image: url("../assets/images/business1.png");
    }
    .card-two{
      background-image: url("../assets/images/business2.png");
    }
    .business{
      width: 528px;
      height: 560px;
      margin: 0 34px;
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #fff;
      background-size: 206px 306px;
      background-position: right bottom;
      background-repeat: no-repeat;
      border-radius: 20px 20px 20px 20px;
      padding: 45px;
      z-index: 1;
      .bg{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 206px;
        height: 306px;
        z-index: 0;
      }
      .title{
        font-weight: bold;
        font-size: 36px;
        color: #1D2129;
      }
      .content{
        flex: 1;
        padding: 25px 0;
        display: flex;
        flex-direction: column;
        .des{
          flex: 1;
          color: #1D2129;
          font-weight: 400;
        }
        .tag{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 16px;
          color: #3A82F9;
          margin: 20px 0;
          >div{
            margin: 6px 0;
          }
          span{
            display: inline-block;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: rgba(58,130,249,0.2);
          }
        }
      }
      .button{
        height: 56px;
        background: linear-gradient( 252deg, #64A9FF 0%, #317AF7 100%);
        border-radius: 8px 8px 8px 8px;
        text-align: center;
        line-height: 56px;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .advantages-box{
    .content{
      flex-direction: column;
      .right{
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .business-box{
    .content-box{
      flex-direction: column;
      align-items: center;
      .business{
        margin-top: 20px;
      }
    }
  }
}

</style>