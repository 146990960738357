<template>
  <div class="contain">
    <NavBar :isBlack="true"></NavBar>
    <div class="banner">
      <div class="banner-content">
        <div class="b-title">
          <span class="text-one">聚合</span>
          <span>支付</span>
        </div>
        <div class="b-des">7 行代码接入支付，B2C、B2B</div>
        <div class="b-des last">渠道场景全覆盖。</div>
      </div>
      <About></About>
    </div>

    <div class="pain-box">
      <div class="title-box">
        <div class="title">市场痛点</div>
        <div>Market pain points</div>
      </div>
      <div class="tab">
        <div :class="activeIndex===0?'tab-active':''" class="tab-item" @click="clickTab(0)">
          <div class="icon-b">
            <img alt="" src="../assets/images/p-pain-icon1.png">
          </div>
          B2C
        </div>
        <div :class="activeIndex===1?'tab-active':''" class="tab-item" @click="clickTab(1)">
          <div class="icon-b">
            <img alt="" src="../assets/images/p-pain-icon2.png">
          </div>
          B2B
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          <div class="img-b">
            <img alt="" src="../assets/images/p-pain1.png">
          </div>
          <span>{{ textObj[activeIndex].text[0] }}</span>
        </div>
        <div class="content">
          <div class="img-b">
            <img alt="" src="../assets/images/p-pain2.png">
          </div>
          <span>{{ textObj[activeIndex].text[1] }}</span>
        </div>
      </div>
    </div>

    <div class="services-box">
      <div class="title-box">
        <div class="title">我们的服务</div>
        <div>OUR SERVICES</div>
      </div>
      <div class="content-box">
        <div class="content">
          <div>
                <span>
                  <img alt="" src="../assets/images/right.png">
                </span>
            一点接入，支持多渠道收付款
          </div>
          <div>
                <span>
                  <img alt="" src="../assets/images/right.png">
                </span>
            清晰的管理后台，高效进行财务管理
          </div>
        </div>
        <div class="img-b">
          <img alt="" src="../assets/images/p-services-img.png">
        </div>
      </div>
    </div>

    <div class="merchants-box">
      <div class="type-box">
        <div class="type-title">
          <div class="title">商家收款</div>
          <div class="img">
            <img alt="" src="../assets/images/p-arrow.png">
          </div>
        </div>
        <div class="types-b">
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type1.png">
            </div>
            <span>手机 APP</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type2.png">
            </div>
            <span>手机 网页</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type3.png">
            </div>
            <span>PC 网页</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type4.png">
            </div>
            <span>QR 扫码</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type5.png">
            </div>
            <span>微信公众号</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type6.png">
            </div>
            <span>小程序支付</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type7.png">
            </div>
            <span>支付宝支付</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type8.png">
            </div>
            <span>银联云支付</span>
          </div>
        </div>
      </div>
      <div class="type-box">
        <div class="type-title">
          <div class="title">商家付款</div>
          <div class="img">
            <img alt="" src="../assets/images/p-arrow.png">
          </div>
        </div>
        <div class="types-b">
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type5.png">
            </div>
            <span>微信公众号</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type7.png">
            </div>
            <span>支付宝</span>
          </div>
          <div class="types">
            <div class="type-img">
              <img alt="" src="../assets/images/p-type9.png">
            </div>
            <span>银行卡</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import About from "@/components/About.vue";

export default {
  components: {About, NavBar},
  data() {
    return {
      activeIndex: 0,
      a: '1111',
      textObj: [
        {
          name: 'B2C',
          text: [
            '渠道太多、接入成本高',
            '业务复杂、财务管理难'
          ]
        }, {
          name: 'B2B',
          text: [
            '收款与订单关联难',
            '付款财务手工制单、操作繁琐'
          ]
        }
      ]
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
    clickTab(index) {
      this.activeIndex = index
    }
  },
};
</script>

<style lang="less" scoped>
.contain {
  background: #F4F7FC;
}

.banner {
  position: relative;
  background-image: url("../assets/images/compliance-bg1.png");
  background-position: center;
  background-size: cover;
}

.banner-content {
  width: 75%;
  margin: 0 auto;
  padding: 100px 0;
  color: #242424;

  .b-title {
    font-size: 48px;
    margin-bottom: 34px;

    .text-one {
      color: #436CE9;
    }
  }

  .b-des {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
  }

  .last {
    margin-bottom: 150px;
  }
}

.title-box {
  font-size: 20px;
  color: #3370FF;
  text-align: center;
  margin-bottom: 74px;

  .title {
    font-size: 36px;
    color: #1D2129;
    font-weight: bold;
    margin-bottom: 18px;
  }
}

.pain-box {
  padding: 74px 0;

  .title-box {
    margin-bottom: 25px;
  }

  .tab {
    display: flex;
    justify-content: center;

    .tab-item {
      width: 211px;
      height: 50px;
      background: #F4F7FC;
      box-shadow: 0px 0px 10px 0px rgba(0, 42, 73, 0.06);
      padding: 13px 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-b {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
    }

    .tab-active {
      background: #fff;
    }
  }

  .content-box {
    display: flex;
    justify-content: center;
    margin-top: 127px;

    .content {
      position: relative;
      width: 400px;
      height: 185px;
      background: #FFFFFF;
      margin: 0 40px;
      padding-top: 10px;
      text-align: center;
      line-height: 155px;
      font-size: 16px;
      color: #414141;

      .img-b {
        position: absolute;
        top: -70px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 129px;
        height: 119px;
      }
    }
  }
}

.services-box {
  position: relative;
  padding-top: 100px;
  background-image: url("../assets/images/compliance-bg2.png");
  background-size: cover;

  .title-box {
    margin-bottom: 0;
  }

  .content-box {
    //position: relative;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 780px;
      font-size: 18px;
      color: #41464F;
      margin: 150px 0;

      > div {
        margin: 30px 0;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
      }

      span {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 32px;
        border-radius: 50%;
        background: rgba(58, 130, 249, 0.2);
        margin-right: 45px;
      }
    }

    .img-b {
      width: 480px;
      height: auto;

      img {
        height: auto !important;
      }
    }
  }

  //.img-b {
  //  position: absolute;
  //  right: 10%;
  //  top: 0;
  //  width: 480px;
  //  height: 612px;
  //}
}

.merchants-box {
  width: 75%;
  margin: 0 auto;
  padding: 40px 0 80px;

  .type-title {
    margin: 40px 0;
    font-weight: bold;
    font-size: 28px;
    color: #1D2129;
    line-height: 54px;
    display: flex;
    align-items: center;

    .img {
      width: 127px;
      height: 28px;
      margin-left: 20px;
    }
  }

  .types-b {
    display: flex;
    flex-wrap: wrap;

    .types {
      width: 280px;
      height: 280px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;

      .type-img {
        width: 207px;
        height: 190px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        color: #353535;
        line-height: 22px;
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .services-box {
    .content-box {
      flex-wrap: wrap;

      .content {
        width: 100%;
        margin: 50px 0 20px;
      }

      .img-b {
        width: 80%;
      }
    }
  }

  .merchants-box {
    width: 100%;
    padding: 40px 50px 80px;
    .types-b{
      justify-content: space-between;
      .types {
        width: 45%;
        margin: 10px;
      }
    }
  }
}
</style>